@font-face {
font-family: '__basisGrotesqueFont_23256e';
src: url(/_next/static/media/0236116641e2786a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__basisGrotesqueFont_23256e';
src: url(/_next/static/media/9a95874553468b2d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__basisGrotesqueFont_23256e';
src: url(/_next/static/media/f61067cf28013e0a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__basisGrotesqueFont_23256e';
src: url(/_next/static/media/f61067cf28013e0a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__basisGrotesqueFont_Fallback_23256e';src: local("Arial");ascent-override: 97.30%;descent-override: 19.94%;line-gap-override: 0.00%;size-adjust: 102.37%
}.__className_23256e {font-family: '__basisGrotesqueFont_23256e', '__basisGrotesqueFont_Fallback_23256e'
}.__variable_23256e {--basisGrotesqueFont: '__basisGrotesqueFont_23256e', '__basisGrotesqueFont_Fallback_23256e'
}

